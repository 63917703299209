import React, { useState } from "react"
import _ from "lodash"
import data from "../../site-metadata.json"

export default () => {
  const [checked, setChecked] = useState(false)

  const handleClick = () => setChecked(!checked)
  return (
    <div class="m-auto w-full sm:w-1/2 p-8">
      <div className="py-8 sm:px-4 overflow-hidden sm:px-6 lg:px-8">
        <div className="relative max-w-xl mx-auto">
          <svg
            className="absolute left-full transform translate-x-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                ></rect>
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            ></rect>
          </svg>
          <svg
            className="absolute right-full bottom-0 transform -translate-x-1/2"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                ></rect>
              </pattern>
            </defs>
            <rect
              width="404"
              height="404"
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            ></rect>
          </svg>
          <div className="text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-base-content sm:text-4xl">
              Contact sales
            </h2>
            <p className="mt-4 text-lg leading-6 text-base-content">
              Feel free to send us an email with your questions
            </p>
          </div>
          <div className="mt-12">
            <form
              action="#"
              method="POST"
              className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              action="https://submit-form.com/7qVM4LMy"
            >
              <input
                type="hidden"
                name="success"
                value={_.get(data, "domain", null) + "/success"}
              />
              <div>
                <label
                  htmlFor="first_name"
                  className="block text-sm font-medium text-base-content"
                >
                  First name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    required
                    autocomplete="given-name"
                    className="input input-bordered w-full text-base-content"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last_name"
                  className="block text-sm font-medium text-base-content"
                >
                  Last name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="last_name"
                    required
                    id="last_name"
                    autocomplete="family-name"
                    className="input input-bordered w-full text-base-content"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="company" className="label-text">
                  Company
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="company"
                    id="company"
                    autocomplete="organization"
                    className="input input-bordered w-full text-base-content"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-base-content"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    required
                    type="email"
                    autocomplete="email"
                    className="input input-bordered w-full text-base-content"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-base-content"
                >
                  Message
                </label>
                <div className="mt-1">
                  <textarea
                    id="message"
                    required
                    name="message"
                    rows="4"
                    className="input input-bordered w-full text-base-content h-36"
                  ></textarea>
                </div>
              </div>
              <div className="sm:col-span-2">
                <div class="inline-flex space-x-1.5 items-center justify-start">
                  <input
                    type="checkbox"
                    class="checkbox checkbox-md"
                    required
                    onClick={handleClick}
                    checked={checked}
                  />
                  <div className="ml-3">
                    <p className="text-sm sm:text-base text-base-content">
                      I agree to the{" "}
                      <a
                        href="/terms"
                        className="font-medium text-base-content text-base-content underline"
                      >
                        Terms
                      </a>{" "}
                      &{" "}
                      <a
                        href="/privacy"
                        className="font-medium text-base-content text-base-content underline"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div className="sm:col-span-2">
                <button
                  type="submit"
                  className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Let's talk
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
