import React from "react"
import Contact from "@components/Contact"
import { ThemeProvider } from "../components/themeContext"
import Header from "@components/Header"
import Footer from "@components/Footer"
import HeaderPromotion from "../components/HeaderPromotion"
import Layout from "../components/Layout"

export default function contact() {
  return (
    <ThemeProvider>
      <Layout>
        <HeaderPromotion />
        <Header />
        <Contact />
        <Footer />
      </Layout>
    </ThemeProvider>
  )
}
